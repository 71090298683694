import { Alert, Stack, theme } from '@prophecy/ui';
import { tokens as alertTokens } from '@prophecy/ui/Alert/tokens';
import { getDuration } from '@prophecy/utils/date';
import { useTimedFlag } from '@prophecy/utils/react/hooks';
import { pluralize } from '@prophecy/utils/string';
import React, { useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { QueryKeys } from '../../common/queries/common';
import { Private_Routes } from '../../common/url';
import { UserTypes } from '../../common/UserTypes';
import { useAppMetadata } from '../../context/appMetadata';
import { getMonitoringAlertInfo } from '../../data/apis/api';
import { useRestQuery } from '../../data/util';
import { MetricType } from './types';

const AlertWrapper = styled.div`
  position: fixed;
  bottom: 60px;
  width: calc(100% - 60px);
  left: 60px;
  z-index: ${theme.zLayer.m};
  display: flex;
  justify-content: center;
  user-select: none;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

const StyledAlert = styled(Alert)`
  a {
    text-decoration: underline;
    ${(props) => props.variant === 'error' && `color: ${alertTokens.Alert.error.color};`}
    ${(props) => props.variant === 'warning' && `color: ${alertTokens.Alert.warning.color};`}
    text-underline-offset: ${theme.spaces.x2};
    font-size: ${alertTokens.Alert.fontSize};
  }
`;

type ServiceDetails = {
  metricsType: MetricType;
  serviceName: string;
  suspensionCountDownInSeconds?: number;
};

type MonitoringAlert = {
  [key in 'critical' | 'warning']: ServiceDetails[];
};

function toMetricName(metricType: MetricType) {
  switch (metricType) {
    case 'CPU_USAGE':
      return 'CPU';
    case 'MEMORY_USAGE':
      return 'Memory';
    case 'DISK_USAGE':
      return 'Disk';
    case 'FILE_COUNT':
      return 'File Storage';
  }
}

export function MonitoringNotification() {
  const isAdminUser = useAppMetadata()?.user?.type === UserTypes.ClusterAdmin;
  const isLoggedIn = Boolean(useAppMetadata()?.user?.id);
  const [showNotice, toggleNotice] = useState<boolean>(true);
  // refetch every 5 minutes
  const { data } = useRestQuery<MonitoringAlert>([QueryKeys.MonitoringAlert], getMonitoringAlertInfo, {
    refetchInterval: 5 * 60 * 1000
  });
  const criticalServices = useMemo(() => data?.data.critical || [], [data]);
  const warningServices = useMemo(() => data?.data.warning || [], [data]);
  const getServiceNames = (details: ServiceDetails[]) => {
    const serviceNames = details.map((service) =>
      service.serviceName.startsWith('sandbox:') ? 'sandbox' : service.serviceName
    );
    const resourceNames = details.map((service) => toMetricName(service.metricsType));

    return {
      serviceNames: Array.from(new Set(serviceNames)).join(', '),
      resourceNames
    };
  };
  const location = useLocation();
  const isMonitoringPage =
    location.pathname.includes(Private_Routes.Settings.tab.getUrl({ tab: 'admin' })) &&
    location.search.includes('tab=monitoring');
  const { serviceNames: criticalServiceNames, resourceNames: criticalResourceNames } =
    getServiceNames(criticalServices);
  const { serviceNames: warningServiceNames, resourceNames: warningResourceNames } = getServiceNames(warningServices);
  const resourceNames = Array.from(new Set([...criticalResourceNames, ...warningResourceNames])).join(', ');
  const suspendingServices = criticalServices
    .filter((service) => Boolean(service.suspensionCountDownInSeconds))
    .map((service) => ({
      serviceName: service.serviceName,
      suspensionCountDown: getDuration(service.suspensionCountDownInSeconds as number, false)
    }));
  const hasAlerts = criticalServices.length > 0 || warningServices.length > 0;

  // if has critical services, snooze for 5 minutes, else 15 minutes
  const timeout = criticalServices.length > 0 ? 5 * 60 * 1000 : 15 * 60 * 1000;
  const [snoozeNotice, toggleSnooze] = useTimedFlag(timeout);

  useEffect(() => {
    if ((criticalServices.length > 0 || warningServices.length > 0) && !snoozeNotice) {
      toggleNotice(true);
    }
  }, [criticalServices, warningServices, snoozeNotice]);

  const getMessage = (serviceNames: string) => {
    const serviceSuspensionMessage =
      suspendingServices.length > 0
        ? `, ${suspendingServices.map((service) => `<strong>${service.serviceName}</strong> will be suspended in <strong>${service.suspensionCountDown}</strong>`).join(' and ')} to prevent data corruption`
        : '';
    return `<strong>${resourceNames}</strong> utilization of ${pluralize(serviceNames.length, 'service')} <strong>${serviceNames}</strong> ${criticalServiceNames.length > 0 ? 'have reached' : 'will reach'} critical limits and requires your immediate attention${serviceSuspensionMessage}${isAdminUser ? '.' : ', please reach out to the cluster admin.'}`;
  };

  let description: React.ReactNode = (
    <div
      dangerouslySetInnerHTML={{
        __html: hasAlerts ? getMessage(criticalServiceNames) : getMessage(warningServiceNames)
      }}
    />
  );

  return isLoggedIn && !isMonitoringPage && showNotice && hasAlerts && !snoozeNotice ? (
    <Draggable>
      <AlertWrapper>
        <StyledAlert
          key='monitoringNotification'
          closable={true}
          variant={criticalServices.length > 0 ? 'error' : 'warning'}
          onClose={() => toggleSnooze()}>
          <Stack direction='horizontal' align='center' gap={theme.spaces.x4}>
            {description}
            {isAdminUser && (
              <Link to={Private_Routes.Settings.tab.getUrl({ tab: 'admin' }, { tab: 'monitoring' })}>More Details</Link>
            )}
          </Stack>
        </StyledAlert>
      </AlertWrapper>
    </Draggable>
  ) : null;
}
