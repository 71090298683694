import { escapeRegExp, get, isObject, mapValues, omit, unset } from 'lodash-es';
import { produceWithoutFreeze } from './data';
export const PATH_KEY = '__path';
export const PATH_SUFFIX_DELIMITER = '__$$__';
const itemParentAndIndexRegex = new RegExp(`^(.*?)\\[(\\d*)\\]($|${escapeRegExp(PATH_SUFFIX_DELIMITER)}.*$)`);
export function updatePath(items, path = '', pathKeyOrUpdater = PATH_KEY) {
    items.forEach((item, index) => {
        const __path = `${path}[${index}]`;
        if (item.children) {
            updatePath(item.children, `${__path}.children`, pathKeyOrUpdater);
        }
        if (typeof pathKeyOrUpdater === 'function') {
            pathKeyOrUpdater(item, __path);
        }
        else {
            item[pathKeyOrUpdater] = __path;
        }
    });
}
export function getAllRowKeys(items, rowKey, allKeys = []) {
    items.forEach((item) => {
        if (item[rowKey] !== undefined) {
            allKeys.push(item[rowKey]);
        }
        if (item.children) {
            getAllRowKeys(item.children, rowKey, allKeys);
        }
    });
    return allKeys;
}
export function removePath(items, pathKey = PATH_KEY) {
    items.forEach((item) => {
        if (item.children) {
            removePath(item.children, pathKey);
        }
        delete item[pathKey];
    });
}
export function removeFromPath(items, path) {
    // https://regex101.com/r/cabp2K/1
    const match = path.match(itemParentAndIndexRegex);
    // if the path is an array index, splice from that index, or else unset the property
    if (match) {
        const [, parentPath, index] = match;
        const parent = get(items, parentPath);
        if (parent) {
            parent.splice(Number(index), 1);
        }
    }
    else {
        unset(items, path);
    }
}
export function getIndexAndParentPath(path) {
    // https://regex101.com/r/cabp2K/1
    const match = path.match(itemParentAndIndexRegex);
    if (!match)
        throw new Error('incorrect path is set to item');
    return {
        parentPath: match[1],
        index: Number(match[2])
    };
}
function replaceBracketsToDot(str) {
    return str.replace(/\[(\d+)\]/g, '.$1');
}
function replaceIndexToBrackets(str) {
    return str.replace(/\.(\d+)/g, '[$1]');
}
export function getCommonParent(path1, path2) {
    const path1ParentParts = replaceBracketsToDot(path1).split('.').slice(0, -1);
    const path2ParentParts = replaceBracketsToDot(path2).split('.').slice(0, -1);
    const commonParts = path1ParentParts.filter((part, index) => part === path2ParentParts[index]);
    return replaceIndexToBrackets(commonParts.join('.'));
}
export function getItemIndex(path, parentPath) {
    const match = path.replace(parentPath, '').match(/^\[(\d+)\]/);
    if (!match)
        throw new Error('incorrect path is set to item');
    return Number(match[1]);
}
export function filterWith(data, predicate) {
    const filterRecursively = (data) => {
        return data.filter((item) => {
            var _a;
            // check if the item matches the filter predicate
            if (predicate(item)) {
                return true;
            }
            // if it has any children, filter the children
            if (item.children) {
                item.children = filterRecursively(item.children);
            }
            // if it has any filtered children return true
            return !!((_a = item.children) === null || _a === void 0 ? void 0 : _a.length);
        });
    };
    /**
     * We don't produce directly with data as we apply filter on it,
     * which returns new array. Immer doesn't support returning new data
     * if draft is being mutated in nested level
     */
    return produceWithoutFreeze({ data }, (obj) => {
        obj.data = filterRecursively(obj.data);
    }).data;
}
export function searchAndFilter(searchStr, data, property) {
    const _searchStr = searchStr.toLowerCase();
    // if the search string is empty return all data
    if (!searchStr)
        return data;
    return filterWith(data, (item) => {
        var _a;
        if (!property) {
            const found = Object.keys(item).some((key) => {
                const value = item[key];
                return typeof value === 'string' && value.toLowerCase().includes(_searchStr);
            });
            // if we find match on any property return true
            if (found)
                return true;
        }
        else if (property && ((_a = get(item, property)) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(_searchStr))) {
            return true;
        }
        return false;
    });
}
export function deepRemoveKeys(obj, keysToRemove) {
    return isObject(obj) ? mapValues(omit(obj, keysToRemove), (value) => deepRemoveKeys(value, keysToRemove)) : obj;
}
